<template>
  <div class="ListDisplay">
    <PocTable
      v-loading="ListDisplayData.loading"
      :data="ListDisplayData.tableData"
      :height="height"
      :current-page.sync="pageConfig.pageNum"
      :total="ListDisplayData.total"
      style="width: 100%"
      @size-change="pageSizeChange"
      @page-current-change="pageChange"
    >
      <span slot="tip" class="tipOp">操作日志</span>
      <el-table-column
        v-for="item in ListDisplayData.columnConfig"
        v-if="item.visible"
        :key="item.key"
        :prop="item.key"
        :label="item.name"
        :fixed="item.fixed"
        :sortable="item.sortable"
        :width="item.width"
      ></el-table-column>
      <el-table-column fixed="right" label="操作内容">
        <template slot-scope="scope">
          <spreadContent :scope="scope.row" @onConfirm="onConfirm" />
        </template>
      </el-table-column>
      <!--
  // {
  //   key: 'age',
  //   name: '操作内容',
  //   dataType: 'string',
  //   visible: true,
  //   sortable: false,
  //   fixed: null,
  //   width: null
  // }-->
    </PocTable>
    <spreadDialog ref="spreadDialogRef" />
  </div>
</template>

<script>
import { ListDisplayTable } from '@/views/orgManage/columnConfig/ListDisplayTable'

import { getTableData } from '@/views/orgManage/Hooks/getTableData'
import spreadContent from '@/views/orgManage/components/spreadContent'
import spreadDialog from '@/views/orgManage/components/spreadDialog'
export default {
  mixins: [$PCommon.TableMixin],
  name: 'OperatingTable',
  components: {
    spreadContent,
    spreadDialog
  },
  props: {
    orgId: {
      required: true
    }
  },
  mounted() {
    // this.getHeight()
  },
  data() {
    return {
      height: 100,
      ListDisplayData: {
        loading: false,
        columnConfig: ListDisplayTable,
        tableData: [],
        total: 0,

        id: ''
      }
    }
  },
  watch: {
    orgId: {
      handler(val) {
        if (val) {
          this.id = val
          this.queryApi(val)
          this.getHeight()
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getHeight() {
      this.$nextTick(() => {
        let baseForm = document.getElementById('baseForm')
        let rightContent = document.getElementById('rightContent')
        let h = rightContent.clientHeight - baseForm.clientHeight - 197
        if (h <= 300) {
          this.height = 300 + 'px'
        } else {
          this.height = h + 'px'
        }
      })
    },
    onConfirm(val) {
      this.$refs.spreadDialogRef.show(val)
    },
    queryApi(data) {
      const obj = {
        orgId: this.id,
        pageNum: this.pageConfig.pageNum,
        pageSize: this.pageConfig.pageSize
      }
      getTableData(this.ListDisplayData, obj)
    }
  }
}
</script>
<style lang="scss" scoped>
.ListDisplay {
  :deep(.poc-table) {
    margin-top: 30px;
    .tipOp {
      font-size: 16px;
      font-weight: bold !important;
      color: #606266 !important;
    }
  }
}
</style>
