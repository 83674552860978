export const ListDisplayTable = [
  {
    key: 'createUser',
    name: '操作人',
    dataType: 'number', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
    visible: true, // 是否展示在表格列中
    sortable: false, // 是否可以排序
    fixed: null, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'createTime',
    name: '操作时间',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: null,
    width: 150
  }
  // {
  //   key: 'age',
  //   name: '操作内容',
  //   dataType: 'string',
  //   visible: true,
  //   sortable: false,
  //   fixed: null,
  //   width: null
  // }
]
