import { getHistoryLog } from '@/api/org'
export const getTableData = (ctx, data) => {
  ctx.loading = true
  ctx.tableData = []
  getHistoryLog(data).then((res) => {
    ctx.loading = false
    if (res && res.code === 200) {
      if (res.data.list) {
        ctx.tableData = res.data.list
        ctx.total = res.data.total
      } else {
        ctx.tableData = []
      }
    }
  })
}
