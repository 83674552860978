<template>
  <div class="orgManage">
    <div class="orgManage__top">
      <div class="orgManage__top__title">
        <div class="orgManage__top__title__font">组织管理</div>
        <el-button type="primary" role="export" v-permission @click="exportOrg"
          >导出</el-button
        >
      </div>
      <div class="orgManage__top__search">
        <el-form label-position="top">
          <el-row>
            <el-col :lg="4">
              <el-form-item label="组织状态">
                <el-select
                  v-model="orgStatus"
                  @change="changeTree"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in orgStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="orgManage__content">
      <div class="orgManage__content__left">
        <orgTree ref="treeRef" @onSelect="treeSelect" @noData="noData" />
      </div>
      <div
        class="orgManage__content__right"
        id="rightContent"
        v-loading="loading"
        element-loading-text="请稍后.."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 1)"
      >
        <div v-if="displayStatus" class="orgManage__content__right__topButton">
          <div class="orgManage__content__right__topButton__title">
            {{ selectData.name }}
          </div>
          <div
            class="orgManage__content__right__topButton__rightButton"
            v-if="isDisButton"
          >
            <el-button
              v-if="isButtonShow('department')"
              role="addOrg"
              v-permission
              @click="addDepartment"
              >新建部门</el-button
            >
            <el-button
              v-if="isButtonShow('companyLow')"
              role="addCompany"
              v-permission
              @click="addCompany"
              >新建下级公司</el-button
            >
            <el-button
              v-if="isButtonShow('group')"
              role="addGroup"
              v-permission
              @click="addGroup"
              >新建集团</el-button
            >
            <el-button type="primary" role="edit" v-permission @click="edit"
              >编辑</el-button
            >
          </div>
          <div class="orgManage__content__right__topButton__rightButton" v-else>
            <el-button role="edit" v-permission @click="goBack">返回</el-button>
            <el-button type="primary" role="edit" v-permission @click="editSave"
              >保存</el-button
            >
          </div>
        </div>
        <div v-if="displayStatus" class="orgManage__content__right__content">
          <!--          <transition name="fade-transform" mode="out-in">-->
          <!--          <transition name="fade">-->
          <component
            ref="baseComDataRef"
            :is="displayStatus"
            :orgId="selectData.id"
            v-slot:buttonOther
          >
            <el-button class="otherButton" @click="other" v-if="isOtherButton"
              >更多</el-button
            >
          </component>

          <!--          </transition>-->
          <!--          </transition>-->
        </div>

        <el-empty v-if="!displayStatus" :image-size="150"></el-empty>
        <DialogForm ref="DialogFormRef" @onConfirm="onConfirm" />
      </div>
    </div>
  </div>
</template>

<script>
import orgTree from '@/views/orgManage/components/orgTree.vue'
// 集团详情
import ListGroupDisplay from '@/views/orgManage/components/ListGroupDisplay'
// 集团编辑
import EditGroupDisplay from '@/views/orgManage/components/EditGroupDisplay'
// 新建集团
import AddGroupDisplay from '@/views/orgManage/components/AddGroupDisplay'
// 新建下级公司
import AddCompanyDisplay from '@/views/orgManage/components/AddCompanyDisplay'
// 下级公司详情
import ListCompanyDisplay from '@/views/orgManage/components/ListCompanyDisplay'
// 下级公司编辑
import EditCompanyDisplay from '@/views/orgManage/components/EditCompanyDisplay'
//新增部门
import AddDepartmentDisplay from '@/views/orgManage/components/AddDepartmentDisplay'
//部门详情
import ListDepartmentDisplay from '@/views/orgManage/components/ListDepartmentDisplay'
//部门编辑
import EditDepartmentDisplay from '@/views/orgManage/components/EditDepartmentDisplay'

import DialogForm from '@/components/DialogForm/DialogForm'

import { EditSave, sysOrgExport } from '@/api/org'
import {
  removeSelectData,
  setSelectData
} from '@/views/orgManage/Hooks/orgStorege'
import { COMPANY, DEPARTMENT, GROUPE } from '@/views/orgManage/constant'
import { orgOrganizationsEdit } from '@/api/organization'

const { Message } = $PCommon
export default {
  name: 'orgManage',
  components: {
    orgTree,
    ListGroupDisplay,
    EditGroupDisplay,
    AddDepartmentDisplay,
    AddCompanyDisplay,
    ListCompanyDisplay,
    EditCompanyDisplay,
    AddGroupDisplay,
    ListDepartmentDisplay,
    EditDepartmentDisplay,
    DialogForm
  },
  data() {
    return {
      orgStatus: 2,
      orgStatusList: [
        {
          label: '已启用',
          value: 0
        },
        {
          label: '停用',
          value: 1
        },
        {
          label: '全部',
          value: 2
        }
      ],
      buttonRole: [],
      selectData: {
        children: [],
        id: '',
        depth: '',
        name: '',
        parentId: '',
        type: '',
        solution_hub_id: '',
        solution_hub_name: ''
      },
      noDataStatus: true,
      displayStatus: 'ListGroupDisplay',
      loading: true,
      status: '',
      otherData: {}
    }
  },
  watch: {
    // 组织变化之后，判断按钮权限
    'selectData.type': {
      handler(val) {
        if (val) {
          this.isButtonShow()
        }
      }
    },
    displayStatus: {
      handler() {
        //重新赋值一下刷新界面重新请求接口。
        let id = this.selectData.id
        this.selectData.id = ''
        this.selectData.id = id
      }
    }
  },
  computed: {
    isDisButton() {
      let arr = [
        'ListGroupDisplay',
        'ListCompanyDisplay',
        'ListDepartmentDisplay'
      ]
      return arr.includes(this.displayStatus)
    },
    isOtherButton() {
      if (
        this.selectData.depth === 3 &&
        this.displayStatus !== 'AddDepartmentDisplay' &&
        this.displayStatus !== 'AddCompanyDisplay'
      ) {
        return true
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    //离开组织管理清空点击的值
    if (to.path !== '/org/orgManage') {
      removeSelectData()
      next()
    }
    next()
  },
  mounted() {
    console.dir(this.$refs.baseComDataRef)
  },
  created() {
    //刷新浏览器清空点击的值
    window.addEventListener('beforeunload', function (e) {
      removeSelectData()
    })
  },
  methods: {
    onConfirm(val) {
      if (val) {
        val.id = this.selectData.id
        val.type = 'org'
        this.otherData = val
        orgOrganizationsEdit(val).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('修改成功')
          }
        })
      }
    },
    other() {
      this.$refs.DialogFormRef.show({
        status: this.status,
        id: this.selectData.id,
        position: 'org',
        solutionHub: {
          solution_hub_id: this.selectData.solution_hub_id,
          solution_hub_name: this.selectData.solution_hub_name
        }
      })
    },
    exportOrg() {
      const obj = {
        isDataRight: 1,
        status: this.orgStatus
      }
      sysOrgExport(obj)
    },
    noData(val) {
      this.displayStatus = val
    },
    changeTree(val) {
      this.displayStatus = false
      removeSelectData()
      this.$refs.treeRef.getTreeData(val)
    },
    edit() {
      this.status = 'edit'
      if (this.selectData.type === GROUPE) {
        this.displayStatus = 'EditGroupDisplay'
      } else if (this.selectData.type === COMPANY) {
        this.displayStatus = 'EditCompanyDisplay'
      } else if (this.selectData.type === DEPARTMENT) {
        this.displayStatus = 'EditDepartmentDisplay'
      }
    },
    editSave() {
      let baseComDataRef = this.$refs.baseComDataRef.getData()
      console.log(baseComDataRef)
      if (baseComDataRef) {
        const obj = { ...baseComDataRef.formInline }
        EditSave(obj).then((res) => {
          if (res) {
            if (res.code === 200) {
              Message.success(res.msg)
              // this.analysisDisplayModule()
              this.$refs.treeRef.getTreeData(this.orgStatus)
            }
          }
        })
      }
      if (this.selectData.depth === 3) {
        if (JSON.stringify(this.otherData) !== '{}') {
          console.log(this.otherData)
          orgOrganizationsEdit(this.otherData).then((res) => {})
        }
      }
    },
    analysisDisplayModule() {
      this.status = 'details'
      if (this.selectData.type === GROUPE) {
        this.displayStatus = 'ListGroupDisplay'
      } else if (this.selectData.type === COMPANY) {
        this.displayStatus = 'ListCompanyDisplay'
      } else if (this.selectData.type === DEPARTMENT) {
        this.displayStatus = 'ListDepartmentDisplay'
      }
    },
    addDepartment() {
      this.displayStatus = 'AddDepartmentDisplay'
    },
    addCompany() {
      this.status = 'add'
      this.displayStatus = 'AddCompanyDisplay'
    },
    addGroup() {
      this.displayStatus = 'AddGroupDisplay'
    },
    treeSelect(val) {
      this.loading = true
      if (val) {
        console.log('treeSelecttreeSelect', val)
        setSelectData(val)
        setTimeout(() => {
          this.loading = false
          Object.keys(this.selectData).forEach((item) => {
            this.selectData[item] = val[item]
          })
          this.analysisDisplayModule()
        }, 500)
      }
    },
    goBack() {
      this.analysisDisplayModule()
    },
    isButtonShow(type) {
      if (this.selectData.type === GROUPE) {
        return true
      } else if (this.selectData.type === COMPANY) {
        let arr = ['companyLow', 'department']
        return arr.includes(type)
      } else if (this.selectData.type === DEPARTMENT) {
        let arr = ['department']
        return arr.includes(type)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin gen {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0;
}
.orgManage {
  &__top {
    height: 141px;
    background: #ffffff;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    @include gen;
    padding: 20px;
    &__title {
      display: flex;
      justify-content: space-between;
      //margin: 20px;
      &__font {
        font-size: 21px;
        font-family: PingFangSC-Medium, PingFang SC, serif;
        font-weight: 500;
        color: #303133;
      }
    }
    &__search {
      //margin: 20px;
      position: relative;
      .el-form {
        margin-top: 15px;
        .el-form-item {
          margin-bottom: 0 !important;
        }
      }
    }
  }
  &__content {
    height: calc(100vh - 60px - 141px - 35px);
    margin-top: 15px;
    @include gen;
    display: flex;
    box-sizing: border-box;
    border-radius: 4px;
    &__left {
      width: 360px;
      height: 100%;
      margin-right: 15px;
    }
    &__right {
      flex: 1;
      height: 100%;
      background: #ffffff;
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
      &__topButton {
        display: flex;
        justify-content: space-between;
        margin: 20px;
        &__title {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC, serif;
          font-weight: 500;
          color: #303133;
        }
      }
      &__content {
        height: calc(100vh - 60px - 141px - 20px - 28px - 65px);
        margin: 20px;
        position: relative;
        overflow: scroll;
      }
    }
  }
  .otherButton {
    margin-top: 10px;

    //position: absolute;
    //z-index: auto;
    //width: 60px;
    //right: 500px;
    //top: -;
    //left: 705px;
  }
}
</style>
