<script>
export default {
  name: 'spreadContent',
  props: {
    scope: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      changeLogList: {}
    }
  },
  watch:{
    scope:{
      handler(val){
        this.changeLogList = val
      },
      deep:true,
      immediate:true
    }
  },
  methods:{
    nodeClick(){
      const obj = {
        logId: this.changeLogList.id
      }

      this.$emit('onConfirm', obj)
    }
  },
  render(createElement, context) {
    const mapToChangeHTML = () => {
      return this.changeLogList.changeLogList.map((item) => {
        if (item.isChange) {
          return (
            <span>
              <span>{item.key}</span> :{' '}
              <span style="font-weight: bold">{item.value || ' - '}</span>
              {' / '}
            </span>
          )
        } else {
          return (
            <span>
              <span>{item.key}</span> : <span>{item.value || ' - '}</span>
              {' / '}
            </span>
          )
        }
      })
    }
    return (
      <div onClick={this.nodeClick} class="overLine">
        {mapToChangeHTML()}
      </div>
    )
  }
}
</script>

<style lang="scss" scoped>
.overLine {
  &:hover {
    text-decoration: underline !important;
    cursor: pointer;
  }
}
</style>
