import { getOrg } from '@/api/org'

export const getData = (ctx, data) => {
  ctx.loading = true
  const obj = {
    id: data.orgId
  }
  getOrg(obj).then((res) => {
    ctx.loading = false
    if (res && res.code === 200) {
      Object.keys(res.data).forEach((item) => {
        ctx.formInline[item] = res.data[item]
      })
    }
  })
}
