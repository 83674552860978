<template>
  <div class="AddDepartmentDisplay">
    <el-form
      v-loading="AddDepartmentDisplay.loading"
      id="baseForm"
      element-loading-text="请稍后.."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 1)"
      :inline="true"
      :model="AddDepartmentDisplay.formInline"
      class="demo-form-inline"
    >
      <el-form-item label="组织编码">
        <el-input
          v-model="AddDepartmentDisplay.formInline.orgCode"
          placeholder="组织编码"
        ></el-input>
      </el-form-item>
      <el-form-item label="组织名称">
        <el-input
          v-model="AddDepartmentDisplay.formInline.orgName"
          placeholder="组织名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="部门主管">
        <el-autocomplete
          class="inline-input"
          v-model="AddDepartmentDisplay.formInline.orgLeader"
          :fetch-suggestions="querySearch"
          value-key="name"
          placeholder="部门主管"
          :trigger-on-focus="false"
          @select="handleSelect"
        >
        </el-autocomplete>
      </el-form-item>
      <el-form-item label="部门主管邮箱">
        <el-input
          v-model="AddDepartmentDisplay.formInline.orgLeaderEmail"
          placeholder="部门主管邮箱"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          v-model="AddDepartmentDisplay.formInline.remark"
          show-word-limit
          class="remark"
          maxlength="50"
          placeholder="备注"
        ></el-input>
      </el-form-item>
      <slot name="buttonOther" />
      <div style="margin-top: 10px">
        <el-checkbox
          v-model="AddDepartmentDisplay.formInline.status"
          :true-label="1"
          :false-label="0"
          >启用</el-checkbox
        >
      </div>
    </el-form>
    <OperatingTable :org-id="orgId" />
  </div>
</template>

<script>
import OperatingTable from '@/views/orgManage/components/OperatingTable'

import { getAllUser } from '@/api/org'

export default {
  name: 'AddDepartmentDisplay',
  components: {
    OperatingTable
  },
  props: {
    orgId: {
      required: true
    }
  },
  data() {
    return {
      AddDepartmentDisplay: {
        formInline: {
          id: '',
          invoiceAddress: '',
          invoiceBank: '',
          invoiceBankno: '',
          invoiceCompany: '',
          orgCode: '',
          orgLeader: '',
          orgLeaderId: '',
          orgLeaderEmail: '',
          orgName: '',
          orgParentId: '',
          orgType: 2,
          remark: '',
          status: 1,
          taxNumber: '',
          telNumber: ''
        },
        usersList: [],
        loading: false
      }
    }
  },
  watch: {
    orgId: {
      handler(val) {
        this.AddDepartmentDisplay.formInline.orgParentId = val
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        )
      }
    },
    handleSelect(item) {
      this.AddDepartmentDisplay.formInline.orgLeaderId = item.value
      this.AddDepartmentDisplay.formInline.orgLeaderEmail = item.email
    },
    querySearch(queryString, cb) {
      let restaurants = this.AddDepartmentDisplay.usersList
      let results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants
      cb(results)
    },
    getData() {
      return this.AddDepartmentDisplay
    }
  },
  mounted() {
    getAllUser().then((res) => {
      if (res.code === 200) {
        if (res.data) {
          this.AddDepartmentDisplay.usersList = res.data
        }
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.AddDepartmentDisplay {
  :deep(.el-form) {
    .remark {
      width: 400px;
    }
  }
}
</style>
