<template>
  <div class="ListGroupDisplay">
    <div id="baseForm">
      <el-descriptions title="基础信息">
        <el-descriptions-item label="组织编码">{{
          ListGroupDisplay.formInline.orgCode
        }}</el-descriptions-item>
        <el-descriptions-item label="组织名称">{{
          ListGroupDisplay.formInline.orgName
        }}</el-descriptions-item>
        <el-descriptions-item label="备注">
          {{
            ListGroupDisplay.formInline.remark
              ? ListGroupDisplay.formInline.remark
              : '-'
          }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        v-if="orgId !== '1'"
        title="开票信息"
        :column="3"
        style="margin-top: 30px"
      >
        <el-descriptions-item label="开票名称">{{
          ListGroupDisplay.formInline.invoiceCompany
        }}</el-descriptions-item>
        <el-descriptions-item label="纳税人识别号">{{
          ListGroupDisplay.formInline.taxNumber
        }}</el-descriptions-item>
        <el-descriptions-item label="开票地址">
          {{ ListGroupDisplay.formInline.invoiceAddress }}
        </el-descriptions-item>
        <el-descriptions-item label="开票公司银行">
          {{ ListGroupDisplay.formInline.invoiceBank }}
        </el-descriptions-item>
        <el-descriptions-item label="开票银行账号">
          {{ ListGroupDisplay.formInline.invoiceBankno }}
        </el-descriptions-item>
        <el-descriptions-item label="联系人电话">
          {{ ListGroupDisplay.formInline.telNumber }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <OperatingTable :orgId="orgId" />
  </div>
</template>

<script>
import { getData } from '@/views/orgManage/Hooks/getData'
import OperatingTable from '@/views/orgManage/components/OperatingTable'

export default {
  components: {
    OperatingTable
  },
  name: 'ListGroupDisplay',
  props: {
    orgId: {
      required: true
    }
  },
  data() {
    return {
      ListGroupDisplay: {
        loading: false,
        formInline: {
          orgCode: '',
          orgName: '',
          remark: ''
        }
      }
    }
  },
  watch: {
    orgId: {
      handler(val) {
        this.queryApi(val)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    queryApi(data) {
      const obj = {
        orgId: data
      }
      getData(this.ListGroupDisplay, obj)
    }
  }
  // setup(props, ctx) {
  //   const ListGroupDisplay = reactive({
  //     loading: false,
  //     formInline: {
  //       orgCode: '',
  //       orgName: '',
  //       remark: ''
  //     }
  //   })
  //   const queryApi = (data) => {
  //     const obj = {
  //       orgId: data
  //     }
  //     getData(ListGroupDisplay, obj)
  //   }
  //   watch(
  //     () => props.orgId,
  //     (newVal, oldValue) => {
  //       queryApi(newVal)
  //     },
  //     { deep: true, immediate: true }
  //   )
  //   return {
  //     ...toRefs(ListGroupDisplay)
  //   }
  // }
}
</script>
<style lang="scss" scoped>
.ListGroupDisplay {
  ::v-deep.poc-table {
    margin-top: 30px;
    .poc-table-tip {
      span {
        //font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC, serif;
        //font-weight: 500 !important;
        //color: #303133 !important;
      }
    }
  }
  :deep(.el-descriptions) {
    .el-descriptions__body {
      .el-descriptions__table {
        .el-descriptions-row {
          .el-descriptions-item {
            .el-descriptions-item__label {
              width: auto !important;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #868686;
            }
            .el-descriptions-item__content {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #303133;
            }
          }
        }
      }
    }
  }
}
</style>
