import { getItem, removeItem, setItem } from '@/utils/storage'

const SELECT_TREE_DATA = 'selectTreeData'

export const removeSelectData = () => {
  removeItem(SELECT_TREE_DATA)
}
export const setSelectData = (val) => {
  setItem(SELECT_TREE_DATA, val)
}
export const getSelectData = () => {
  return getItem(SELECT_TREE_DATA)
}
