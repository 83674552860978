<template>
  <div class="DialogForm">
    <el-drawer
      :visible.sync="dialogVisible"
      direction="btt"
      width="80%"
      size="90%"
      :destroy-on-close="destroy"
      :before-close="close"
    >
      <template v-slot:title>
        <div>{{ titleDis() }}</div>
        <div class="dialog-footer" v-if="!formStatus">
          <el-button @click="close">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
        </div>
      </template>
      <!--            destroy-on-close-->
      <div class="drawer_content">
        <OrganizationInformationForm
          :formStatus="formStatus"
          :orgId="orgId"
          :solutionHub="solutionHub"
          :position="position"
          ref="OrganizationInformationFormRef"
        />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import OrganizationInformationForm from '@/components/DialogForm/components/OrganizationInformationForm'
export default {
  name: 'DialogForm',
  data() {
    return {
      dialogVisible: false,
      status: '',
      id: '',
      solutionHubVal: {},
      position: '',
      destroy: false
    }
  },
  computed: {
    formStatus() {
      return this.status === 'details'
    },
    orgId() {
      return this.id
    },
    solutionHub() {
      return this.solutionHubVal
    }
  },
  components: {
    OrganizationInformationForm
  },
  methods: {
    show(data) {
      if (data) {
        this.status = data.status
        this.id = data.id
        this.solutionHubVal = data.solutionHub
        this.position = data.position
        this.dialogVisible = true
      }
    },
    titleDis() {
      if (this.formStatus) {
        return '机构管理查看'
      } else {
        return '机构管理编辑'
      }
    },
    submit() {
      let RefData = this.$refs.OrganizationInformationFormRef.ruleFormRef
      let ruleFormData = this.$refs.OrganizationInformationFormRef.ruleForm
      RefData.validate((valid) => {
        if (valid) {
          let a = JSON.parse(JSON.stringify(ruleFormData))

          this.$emit('onConfirm', a)
          this.close()
        } else {
          return false
        }
      })
    },
    close() {
      let ruleFormData = this.$refs.OrganizationInformationFormRef.ruleForm
      this.dialogVisible = false
      this.destroy = true
      Object.keys(ruleFormData).forEach((item) => {
        ruleFormData[item] = ''
      })
    }
  }
}
</script>

<style lang="scss">
.DialogForm {
  .el-drawer__wrapper {
    .el-drawer__header {
      margin-bottom: 16px;
      div {
        &:first-child {
          display: inline-block;
          padding-left: 19.2%;
          font-size: 21px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #303133;
        }
      }
    }
    .el-drawer__body {
      .drawer_content {
        width: 100%;
        height: 100%;
        padding: 0 20% 0 20%;
        .el-form {
          .title {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #303133;
            margin-bottom: 12px;
            margin-top: 8px;
          }
          .el-form-item {
            margin-bottom: 32px !important;
          }
        }
      }
    }
  }
}
</style>
