<template>
  <el-dialog
    title="查看操作的内容"
    class="spreadDialog"
    :visible.sync="dialogVisible"
    width="676px"
    destroy-on-close
    :before-close="handleClose"
  >
    <div class="spreadDialog__content">
      <div class="spreadDialog__content__left">
        <div class="spreadDialog__content__left__title">操作前内容</div>
        <el-scrollbar class="spreadDialog__content__left__content">
          <div v-for="item in orgDetailsData.oldData" class="contentItem">
            <span class="contentItemLeft">{{ item.label }}: </span>
            <span class="contentItemRight">{{ item.value }}</span>
          </div>
        </el-scrollbar>
      </div>
      <div class="spreadDialog__content__middle">
        <div class="spreadDialog__content__middle__button">
          <div class="spreadDialog__content__middle__button__circle">></div>
          <div class="spreadDialog__content__middle__button__circle active">
            <
          </div>
        </div>
      </div>
      <div class="spreadDialog__content__right">
        <div class="spreadDialog__content__right__title">操作后内容</div>
        <el-scrollbar class="spreadDialog__content__right__content">
          <div v-for="item in orgDetailsData.newData" class="contentItem">
            <span class="contentItemLeft">{{ item.label }}: </span>
            <span class="contentItemRight">{{ item.value }}</span>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getOperateLog } from '@/api/org'

export default {
  name: 'spreadDialog',
  data() {
    return {
      dialogVisible: false,
      orgData: [
        {
          key: 'orgCode',
          label: '组织机构编码',
          value: ''
        },
        {
          key: 'orgName',
          label: '组织机构名称',
          value: ''
        },

        {
          key: 'remark',
          label: '备注',
          value: ''
        },
        {
          key: 'status',
          label: '启用状态',
          value: ''
        },

        {
          key: 'invoiceAddress',
          label: '开票地址',
          value: ''
        },
        {
          key: 'invoiceBank',
          label: '开票公司银行',
          value: ''
        },
        {
          key: 'invoiceBankno',
          label: '开票银行账号',
          value: ''
        },
        {
          key: 'invoiceCompany',
          label: '开票名称',
          value: ''
        },

        {
          key: 'orgLeader',
          label: '部门主管',
          value: ''
        },
        {
          key: 'orgLeaderEmail',
          label: '部门主管email',
          value: ''
        },
        {
          key: 'orgLeaderId',
          label: '部门主管id',
          value: ''
        },

        {
          key: 'taxNumber',
          label: '纳税人识别号',
          value: ''
        },
        {
          key: 'telNumber',
          label: '联系电话',
          value: ''
        }
      ],
      orgDetailsData: {
        oldData: [],
        newData: []
      }
    }
  },
  methods: {
    getData(val) {
      getOperateLog(val).then((res) => {
        if (res && res.code === 200) {
          if (res.data) {
            this.orgDetailsData = {
              oldData: [],
              newData: []
            }
            let DetailsData = res.data
            let newData = DetailsData.newSysOrg
            let oldData = DetailsData.oldSysOrg
            let newDataList = JSON.parse(JSON.stringify(this.orgData))
            if (newData) {
              Object.keys(newData).forEach((item) => {
                newDataList.forEach((item1) => {
                  if (item === item1.key) {
                    item1.value = newData[item] || ' - '
                    this.orgDetailsData.newData.push(item1)
                  }
                })
              })
            }
            let newDataList1 = JSON.parse(JSON.stringify(this.orgData))
            if (oldData) {
              Object.keys(oldData).forEach((item) => {
                newDataList1.forEach((item1) => {
                  if (item === item1.key) {
                    item1.value = oldData[item] || ' - '
                    this.orgDetailsData.oldData.push(item1)
                  }
                })
              })
            }
            this.dialogVisible = true
          }
        }
      })
    },
    show(val) {
      if (val) {
        this.getData(val)
      }
    },
    handleClose() {
      this.orgDetailsData = {
        oldData: [],
        newData: []
      }
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.spreadDialog {
  &__content {
    display: flex;
    &__left {
      width: 270px;
      height: 330px;
      border-radius: 4px;
      border: 1px solid #ebeef5;
      &__title {
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 14px;
        //font-family: PingFangSC-Regular, PingFang SC, serif;
        font-weight: 400;
        color: #303133;
        background: rgba(235, 238, 245, 1);
      }
      &__content {
        height: 280px;
        padding: 12px 0 0 12px;

        .contentItem {
          margin-bottom: 12px;
          .contentItemLeft {
            font-size: 14px;
            //font-family: PingFangSC-Regular, PingFang SC, serif;
            font-weight: 400;
            color: #868686;
          }
          .contentItemRight {
            font-size: 14px;
            //font-family: PingFangSC-Regular, PingFang SC, serif;
            font-weight: 400;
            color: #303133;
          }
        }
      }
    }
    &__middle {
      position: relative;
      width: 76px;
      height: 330px;
      &__button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &__circle {
          width: 36px;
          height: 36px;
          background: #bb9667;
          opacity: 0.49;
          text-align: center;
          line-height: 35px;
          border-radius: 50%;
          color: white;
          font-size: 20px;
        }
        .active {
          background: #ba9765;
          opacity: 1;
          margin-top: 22px;
        }
      }
    }
    &__right {
      width: 270px;
      height: 330px;
      border-radius: 4px;
      border: 1px solid #ebeef5;
      &__title {
        text-align: center;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        //font-family: PingFangSC-Regular, PingFang SC, serif;
        font-weight: 400;
        color: #303133;
        background: rgba(235, 238, 245, 1);
      }
      &__content {
        height: 280px;
        padding: 12px 0 0 12px;
        .contentItem {
          margin-bottom: 12px;
          .contentItemLeft {
            font-size: 14px;
            //font-family: PingFangSC-Regular, PingFang SC, serif;
            font-weight: 400;
            color: #868686;
          }
          .contentItemRight {
            font-size: 14px;
            //font-family: PingFangSC-Regular, PingFang SC, serif;
            font-weight: 400;
            color: #303133;
          }
        }
      }
    }
  }
}
</style>
