<template>
  <div class="orgTree">
    <div class="orgTree__title">
      <div class="orgTree__title__search">
        <svg-icon icon="search" class="detailsIcon" />
        <el-input placeholder="请输入查询" v-model="treeSearchValue"></el-input>
      </div>
    </div>
    <div
      class="orgTree__content"
      v-loading="loading"
      element-loading-text="请稍后.."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 1)"
    >
      <!--          :default-expanded-keys="defaultExpandedKeys"-->
      <el-tree
        v-if="data.length"
        ref="treeRef"
        class="orgTree_tree"
        :data="data"
        node-key="id"
        :expand-on-click-node="false"
        default-expand-all
        :props="defaultProps"
        :filter-node-method="filterNode"
        @node-click="handleNodeClick"
        @check="checkChange"
      ></el-tree>
      <el-empty v-else :image-size="150"></el-empty>
    </div>
  </div>
</template>

<script>
import { getTree } from '@/api/org'
import { check, typeRange } from '@/utils/util'
import { getItem, removeItem } from '@/utils/storage'
import { getSelectData } from '@/views/orgManage/Hooks/orgStorege'

export default {
  name: 'orgTree',
  components: {},
  props: {
    treeId: {
      type: String,
      default: ''
    }
  },
  created() {
    this.getTreeData()
  },
  data() {
    return {
      treeSearchValue: '',
      data: [
        {
          id: '1',
          name: '',
          parentId: '0',
          type: 0,
          children: []
        }
      ],
      loading: false,
      defaultCheckedKeys: [],
      defaultExpandedKeys: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      }
    }
  },
  watch: {
    treeId: {
      handler(treeVal) {},
      immediate: true,
      deep: true
    },
    treeSearchValue(val) {
      this.$refs.treeRef.filter(val)
      removeItem('selectTreeData')
    }
  },
  methods: {
    checkChange(data) {
      console.log('checkChange', data)
    },
    handleNodeClick(data) {
      this.$emit('onSelect', data)
    },
    filterNode(value, data) {
      console.log(data)
      if (!value) return true
      let targetName = data.name.toLowerCase()
      let objName = value.toLowerCase()
      return targetName.indexOf(objName) !== -1
    },
    closeLoad() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    getTreeData(val) {
      const obj = {
        isDataRight: 1,
        status: val
      }
      this.loading = true
      getTree(obj)
        .then((res) => {
          this.closeLoad()
          if (res.code === 200) {
            setTimeout(() => {
              if (res.data) {
                this.data = res.data
                if (check(this.data) === typeRange.array) {
                  let treeData = getSelectData()
                  if (treeData) {
                    this.$nextTick(() => {
                      this.$refs.treeRef.setCurrentKey(treeData.id)

                      let clickPoint = this.$refs.treeRef.getCurrentNode()
                      if (clickPoint) {
                        this.handleNodeClick(clickPoint)
                      }
                    })
                  } else {
                    if (this.data.length) {
                      // this.defaultExpandedKeys.push(this.data[0].id)
                      this.handleNodeClick(this.data[0])
                    }
                  }
                }
              } else {
                this.$emit('noData', false)
                this.data = []
              }
            }, 600)
          }
        })
        .catch((err) => {
          this.closeLoad()
        })
    }
  }
}
</script>

<style lang="scss">
.orgTree {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  &__title {
    height: 70px;
    border-bottom: 1px solid #f4f4f4;
    position: relative;
    &__search {
      width: 320px;
      height: 30px;
      border-radius: 15px;
      border: 1px solid #c4c8cf;
      display: flex;
      align-items: center;
      position: absolute;
      top: 20px;
      left: 20px;
      .detailsIcon {
        font-size: 18px;
        margin-left: 10px;
      }
      .el-input {
        .el-input__inner {
          width: 280px;
          border: 0;
          padding-left: 5px;
        }
      }
    }
  }
  &__content {
    height: 100%;
    padding: 18px 20px 0 20px;
    .el-tree {
      height: calc(100vh - 60px - 141px - 71px - 18px - 20px - 20px);
      overflow: scroll;
      .el-tree--highlight-current,
      .el-tree-node.is-current,
      > .el-tree-node__content {
        color: #bb9667;
        background: none;
      }
      .el-tree-node.is-current .el-tree-node__expand-icon {
        color: #bb9667;
      }
      .el-tree-node__label {
        font-size: 14px;
        //font-family: PingFangSC-Regular, PingFang SC, serif;
        font-weight: 400;
      }
    }
  }
}
</style>
