<template>
  <div class="ListDepartmentDisplay">
    <el-descriptions title="基础信息" id="baseForm" :column="3">
      <el-descriptions-item label="组织编码">{{
        ListDepartmentDisplay.formInline.orgCode
      }}</el-descriptions-item>
      <el-descriptions-item label="组织名称">{{
        ListDepartmentDisplay.formInline.orgName
      }}</el-descriptions-item>
      <el-descriptions-item label="部门主管">{{
        ListDepartmentDisplay.formInline.orgLeader
      }}</el-descriptions-item>
      <el-descriptions-item label="部门主管邮箱">{{
        ListDepartmentDisplay.formInline.orgLeaderEmail
      }}</el-descriptions-item>
      <el-descriptions-item label="备注">
        {{
          ListDepartmentDisplay.formInline.remark
            ? ListDepartmentDisplay.formInline.remark
            : '-'
        }}
      </el-descriptions-item>
      <el-descriptions-item label="启用状态">
        {{ ListDepartmentDisplay.formInline.status ? '已启用' : '停用' }}
      </el-descriptions-item>
    </el-descriptions>
    <slot name="buttonOther" />
    <OperatingTable :orgId="orgId" />
  </div>
</template>

<script>
// import { reactive, toRefs, watch } from '@vue/composition-api'
import { getData } from '@/views/orgManage/Hooks/getData'
import OperatingTable from '@/views/orgManage/components/OperatingTable'

export default {
  components: {
    OperatingTable
  },
  name: 'ListDepartmentDisplay',
  props: {
    orgId: {
      required: true
    }
  },
  data() {
    return {
      ListDepartmentDisplay: {
        loading: false,
        formInline: {
          orgCode: '',
          orgName: '',
          remark: ''
        }
      }
    }
  },
  watch: {
    orgId: {
      handler(val) {
        this.queryApi(val)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    queryApi(data) {
      const obj = {
        orgId: data
      }
      getData(this.ListDepartmentDisplay, obj)
    }
  }
  // setup(props, ctx) {
  //   const ListDepartmentDisplay = reactive({
  //     loading: false,
  //     formInline: {
  //       orgCode: '',
  //       orgName: '',
  //       remark: ''
  //     }
  //   })
  //   const queryApi = (data) => {
  //     const obj = {
  //       orgId: data
  //     }
  //     getData(ListDepartmentDisplay, obj)
  //   }
  //   watch(
  //     () => props.orgId,
  //     (newVal, oldValue) => {
  //       queryApi(newVal)
  //     },
  //     { deep: true, immediate: true }
  //   )
  //   return {
  //     ...toRefs(ListDepartmentDisplay)
  //   }
  // }
}
</script>
<style lang="scss" scoped>
.ListDepartmentDisplay {
  ::v-deep.poc-table {
    margin-top: 30px;
    .poc-table-tip {
      span {
        //font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC, serif;
        //font-weight: 500 !important;
        //color: #303133 !important;
      }
    }
  }
  :deep(.el-descriptions) {
    .el-descriptions__body {
      .el-descriptions__table {
        .el-descriptions-row {
          .el-descriptions-item {
            .el-descriptions-item__label {
              width: auto !important;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #868686;
            }
            .el-descriptions-item__content {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #303133;
            }
          }
        }
      }
    }
  }
}
</style>
