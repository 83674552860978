<template>
  <div class="EditGroupDisplay">
    <el-form
      v-loading="EditGroupDisplay.loading"
      id="baseForm"
      element-loading-text="请稍后.."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 1)"
      :inline="true"
      :model="EditGroupDisplay.formInline"
      class="demo-form-inline"
    >
      <el-form-item label="组织编码">
        <el-input
          v-model="EditGroupDisplay.formInline.orgCode"
          placeholder="组织编码"
        ></el-input>
      </el-form-item>
      <el-form-item label="组织名称">
        <el-input
          v-model="EditGroupDisplay.formInline.orgName"
          placeholder="组织名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          v-model="EditGroupDisplay.formInline.remark"
          show-word-limit
          class="remark"
          maxlength="50"
          placeholder="备注"
        ></el-input>
      </el-form-item>
      <div v-if="orgId !== '1'">
        <div class="baseTitle bottomTitle">开票信息</div>
        <el-form-item label="开票名称">
          <el-input
            v-model="EditGroupDisplay.formInline.invoiceCompany"
            placeholder="开票名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="纳税人识别号">
          <el-input
            v-model="EditGroupDisplay.formInline.taxNumber"
            placeholder="纳税人识别号"
          ></el-input>
        </el-form-item>
        <el-form-item label="开票地址">
          <el-input
            v-model="EditGroupDisplay.formInline.invoiceAddress"
            placeholder="开票地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input
            v-model="EditGroupDisplay.formInline.telNumber"
            type="number"
            placeholder="联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="开票公司银行">
          <el-input
            v-model="EditGroupDisplay.formInline.invoiceBank"
            placeholder="开票公司银行"
          ></el-input>
        </el-form-item>
        <el-form-item label="开票银行账号">
          <el-input
            v-model="EditGroupDisplay.formInline.invoiceBankno"
            placeholder="开票银行账号"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
    <OperatingTable :org-id="orgId" />
  </div>
</template>

<script>
import { getData } from '@/views/orgManage/Hooks/getData'
import OperatingTable from '@/views/orgManage/components/OperatingTable'

export default {
  name: 'EditDisplay',
  components: {
    OperatingTable
  },
  props: {
    orgId: {
      required: true
    }
  },
  data() {
    return {
      EditGroupDisplay: {
        formInline: {
          id: '',
          invoiceAddress: '',
          invoiceBank: '',
          invoiceBankno: '',
          invoiceCompany: '',
          orgCode: '',
          orgLeader: '',
          orgLeaderEmail: '',
          orgName: '',
          orgParentId: '',
          orgType: 0,
          remark: '',
          status: 1,
          taxNumber: '',
          telNumber: ''
        },
        loading: false
      }
    }
  },
  methods: {
    queryApi(data) {
      const obj = {
        orgId: data
      }
      getData(this.EditGroupDisplay, obj)
    },
    getData() {
      return this.EditGroupDisplay
    }
  },
  watch: {
    orgId: {
      handler(val) {
        this.EditGroupDisplay.formInline.id = val
        this.queryApi(val)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.EditGroupDisplay {
  :deep(.el-form) {
    .remark {
      width: 400px;
    }
    .baseTitle {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC, serif;
      font-weight: 500;
      color: #303133;
      margin-bottom: 18px;
    }
    .bottomTitle {
      margin-top: 30px;
    }
  }
}
</style>
